/**
* SEO component that queries for data with
*  Gatsby's useStaticQuery React hook
*
* See: https://www.gatsbyjs.org/docs/use-static-query/
*/

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({ description, lang, meta, title, subTitle }) => {
	const { site } = useStaticQuery(
		graphql`
		query {
			site {
				siteMetadata {
					title
					description
					siteUrl
					author
					keywords
				}
			}
		}
		`
	)

	const pageDescription = subTitle ? `%s | ${subTitle}` : `%s | ${site.siteMetadata.title}`

	return (
		<Helmet
			titleTemplate = {pageDescription}
		>
			<html lang="it"/>
			<title> {title || site.siteMetadata.title} </title>
			<base target="_blank" href={site.siteMetadata.siteUrl} />
			<meta name="description" content={description || site.siteMetadata.description} />
			<meta name="keywords" content={site.siteMetadata.keywords.join(', ')} />
			<meta name="author" content="Juse" />
		</Helmet>
	)
}

Seo.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
}

Seo.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
}

export default Seo
