import React, { useState, useEffect } from "react"
import CookieConsent from "react-cookie-consent"
import Cookies from "js-cookie"
import ReactGA from "react-ga"
import { Link } from "gatsby"
import * as styles from "./control_cookie.module.css"


const barStyle = {
	justifyContent: "center",
	overflow: "auto"
}

const acceptStyle = {
	backgroundColor: "#F15B5B",
	color: "white",
	padding: "10px",
	borderRadius: "4px"
}

const denclineStyle = {
	backgroundColor: "white",
	color: "#F15B5B",
	padding: "10px",
	borderRadius: "4px"
}

const contentStyle = {
	flex: "0 1 auto",
	alignSelf: "center",
	textAlign: "center"
}

const ControlCookie = () => {
	const [accept, setAccept] = useState(false);
	

	useEffect( () => {
		const cookieOptions = {
			anonymizeIp: true,
			allowAdFeatures: false
		}

		if( process.env.GATSBY_CUR_ENVIRONMENT !== "development" ) {
			if( Cookies.get("gdpr-analytics-enabled") === "true" ) {
				ReactGA.initialize(process.env.GATSBY_GA_API_KEY , {gaOptions: cookieOptions});
				ReactGA.pageview( typeof window !== 'undefined' && window.location.pathname + window.location.search);
			}
		}
	}, [accept]);

	return (
		<CookieConsent
			cookieName="gdpr-analytics-enabled"
			acceptOnScroll={false}
			location="bottom"
			expires={999}
			enableDeclineButton
			flipButtons
			buttonText="Accept"
			declineButtonText="Decline"
			style={barStyle}
			buttonWrapperClasses={styles.buttons_wrapper}
			buttonStyle={acceptStyle}
			declineButtonStyle={denclineStyle}
			contentStyle={contentStyle}
			contentClasses={styles.content_wrapper}
			onAccept={({ acceptedByScrolling }) => {
						if( acceptedByScrolling ) {
							// implement if needed
						} else {
							 setAccept(true);
						}
					}}
		>
			This website uses cookies to enhance the user experience.
			See the <Link to="/cookie_policy/" style={{color:"#F15B5B"}}>cookie policy</Link>
			&nbsp;for further details on how cookies are used and how to change your cookie settings.
		</CookieConsent>
	)
}


export default ControlCookie
