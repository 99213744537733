import React from "react"
import { Link } from "gatsby"
import ControlCookie from "./control_cookie"


const footerStyle = {
	display: "flex",
	justifyContent: "center",
	width: "100%",
	bottom: "0",
	padding: "1em"
}

const linkStyle={
	textDecoration: "none",
	color: "black",
	fontFamily: "Roboto",
	fontWeight: 300,
	letterSpacing: "0.1em"
}

const Layout = ({children, nav}) => {
	return (
		<>
			<nav style={{display:"flex", justifyContent:"center"}}>
				{nav}
			</nav>

			<main>
				{children}
			</main>

			<footer>
				<div style={footerStyle}>
					<Link to="/privacy_policy" style={linkStyle}>Privacy Policy</Link>
				</div>
				<ControlCookie />
			</footer>
		</>
	)
}

export default Layout
